import React, { useEffect, useState } from 'react';
import {
  BackTop,
  Button,
  Col,
  Comment,
  Drawer,
  Form,
  Input,
  List,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography
} from 'antd';
import {
  DeleteRowOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  MessageOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  SendOutlined,
} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import 'antd/dist/antd.less';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import * as XLSX from 'xlsx';
import i18n from '../../../../assets/language/i18n';
import Colors from '../../../../assets/colors/Colors';
import ConstantsDate from '../../../../constants/ConstantsDate';
import { actionLoading } from '../../../../store/reducers/loading/loadingActions';
import ResumeConciliation from './ResumeConciliation';
import ConstantsReservationStatus from '../../../../constants/ConstantsReservationStatus';
import { getOtaCode, getWritePermissionByTab } from '../../../../utils/Utils';
import ConstantsRoutes from '../../../../constants/ConstantsRoutes';
import {
  actionGetFiltersPreConciliation
} from '../../../../store/reducers/preConciliation/preConciliationActions';

import NewManualLinesModal from './NewManualLinesModal';
import '../styles/styles.css';

import {
  useGetConciliationDataApiQuery,
  useRemoveManualLineApiMutation,
  useValidateManualLineApiMutation,
  useLazyGetMessagesManualLineApiQuery,
  useLazyPreviewConciliationApiQuery,
  useSendMessageManualLineApiMutation
} from '../../../../services/conciliationLines';

import styles from '../styles/stylesConciliationIndex';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export default function Conciliation() {
  const location = useLocation();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [mySelectedRows, setSelectedRows] = useState([]);
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [messageValue, setMessageValue] = useState('');
  const [rowId, setRowId] = useState(0);
  const [commentChangeStatus, setCommentChangeStatus] = useState('');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.reducerLoading);

  const {
    data: conciliation, isFetching: isFetchingGetConciliationData
  } = useGetConciliationDataApiQuery({idJob: location.state.jobId, idHotel: location.state.hotelId});

  const [
    getMessagesManualLine,
    {
      data: messagesConciliation,
      isFetching: isFetchingGetMessagesManualLine,
      error: errorGetMessagesManualLine
    }
  ] = useLazyGetMessagesManualLineApiQuery();

  const [
    getPreviewConciliation,
    {
      isFetching: isFetchingPreviewConciliation,
      error: errorPreviewConciliation
    }
  ] = useLazyPreviewConciliationApiQuery();

  const [
    removeManualLine,
    {
      data: removeManualLineResult,
      isSuccess: isSuccessRemoveManualLine,
      isLoading: isLoadingRemoveManualLine,
      error: errorRemoveManualLine
    }
  ] = useRemoveManualLineApiMutation();

  const [
    validateManualLine,
    {
      data: validateManualLineResult,
      isSuccess: isSuccessValidateManualLine,
      isLoading: isLoadingValidateManualLine,
      error: errorValidateManualLine
    }
  ] = useValidateManualLineApiMutation();

  const [
    sendMessageManualLine,
    {
      data: sendMessageManualLineResult,
      isSuccess: isSuccessSendMessageManualLine,
      isLoading: isLoadingSendMessageManualLine,
      error: errorSendMessageManualLine
    }
  ] = useSendMessageManualLineApiMutation();

  useEffect(() => {
    if (!loading) {
      dispatch(actionLoading(true));
      dispatch(actionGetFiltersPreConciliation());
    }
  }, []);

  useEffect(() => {
    if (isSuccessRemoveManualLine) {
      message.success(removeManualLineResult.message);
    }
    if (errorRemoveManualLine) {
      message.error(errorRemoveManualLine.message);
    }
  }, [isSuccessRemoveManualLine, errorRemoveManualLine]);

  useEffect(() => {
    if (isSuccessValidateManualLine) {
      message.success(validateManualLineResult.message);
    }
    if (errorValidateManualLine) {
      message.error(errorValidateManualLine.message);
    }
  }, [isSuccessValidateManualLine, errorValidateManualLine]);

  useEffect(() => {
    if (errorGetMessagesManualLine) {
      message.error(errorGetMessagesManualLine.message);
    }
    if (errorSendMessageManualLine) {
      message.error(errorSendMessageManualLine.message);
    }
    if (isSuccessSendMessageManualLine) {
      message.success(sendMessageManualLineResult?.message);
    }
  }, [errorGetMessagesManualLine, errorSendMessageManualLine, isSuccessSendMessageManualLine]);

  useEffect(() => {
    if (errorPreviewConciliation) {
      message.error(errorPreviewConciliation.message);
    }
  }, [errorPreviewConciliation]);

  const handleCancel = () => {
    setVisibleModal(false);
  };

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const getColumnSearchProps = (dataIndex, searchInput) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => {
      let hintSearch;
      if (dataIndex === 'reservation_id') {
        hintSearch = t('hint_number_locator');
      } else {
        hintSearch = t('text_headline');
      }
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={hintSearch}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={styles.input}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={styles.buttonPrimary}
            >
              {t('text_button_filter')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={styles.buttonDefault}>
              {t('text_button_clear')}
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? Colors.PRIMARY_COLOR : Colors.ICON_COLOR }}
      />
    ),
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),

    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text, record) => {
      if (dataIndex === 'reservation_id') {
        const recordStatus = record.validado;
        let styleHigh;
        let styleNormal;
        let tooltipStatus;
        if (recordStatus === ConstantsReservationStatus.Pending) {
          styleHigh = styles.textValueHighPending;
          styleNormal = styles.textValuePending;
          tooltipStatus = t('text_pending');
        } else if (recordStatus === ConstantsReservationStatus.Validate) {
          styleHigh = styles.textValueHighValidate;
          styleNormal = styles.textValueValidate;
          tooltipStatus = t('text_validate');
        } else if (recordStatus === ConstantsReservationStatus.Exception) {
          styleHigh = styles.textValueHighException;
          styleNormal = styles.textValueException;
          tooltipStatus = t('text_exception');
        } else if (recordStatus === ConstantsReservationStatus.Exclude) {
          styleHigh = styles.textValueHighExclude;
          styleNormal = styles.textValueExclude;
          tooltipStatus = t('text_exclude');
        } else if (recordStatus === ConstantsReservationStatus.PreConciliate) {
          styleHigh = styles.textValueHighPreConciliate;
          styleNormal = styles.textValuePreConciliate;
          tooltipStatus = t('text_pre_conciliate');
        } else if (recordStatus === ConstantsReservationStatus.Conciliate) {
          styleHigh = styles.textValueHighConciliate;
          styleNormal = styles.textValueConciliate;
          tooltipStatus = t('text_conciliate');
        } else if (recordStatus === ConstantsReservationStatus.Facture) {
          styleHigh = styles.textValueHighFacture;
          styleNormal = styles.textValueFacture;
          tooltipStatus = t('text_facture');
        } else {
          styleHigh = styles.textValueHighOther;
          styleNormal = styles.textValueOther;
        }
        if (searchedColumn === dataIndex) {
          return (
            <Tooltip title={tooltipStatus}>
              <Highlighter
                highlightStyle={styleHigh}
                unhighlightStyle={styleNormal}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
              />
            </Tooltip>
          );
        } return (
          <Tooltip title={tooltipStatus}>
            <Text style={styleNormal}>
              {text}
            </Text>
          </Tooltip>
        );
      }
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        );
      } return (
        <Text className="text">
          {text}
        </Text>
      );
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleOk = () => {
    setVisibleModal(false);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const rowSelection = () => ({
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: !getWritePermissionByTab(ConstantsRoutes.CONCILIATION)
        || record.id_arreglo === null,
      name: record.id_arreglo,
    })
  });

  const showConfirm = (validate) => {
    const { confirm } = Modal;

    if (validate === 0 || validate === 3) {
      confirm({
        title: t('text_question_validate'),
        icon: <ExclamationCircleOutlined />,
        content: <TextArea
          showCount
          allowClear
          placeholder={t('text_select_reservation')}
          rows={3}
          maxLength={130}
          onChange={(e) => {
            setCommentChangeStatus(e.target.value);
          }}
        />,
        onOk: () => {
          const reservations = [];
          mySelectedRows.forEach((item) => {
            reservations.push({
              id_arreglo: item.id_arreglo,
              validado: validate,
              mensaje: commentChangeStatus,
            });
          });
          validateManualLine(reservations);
        },
      });
    } else {
      confirm({
        title: t('text_question_validate'),
        icon: <ExclamationCircleOutlined />,
        onOk: () => {
          const reservations = [];
          mySelectedRows.forEach((item) => {
            reservations.push({
              id_arreglo: item.id_arreglo,
              validado: validate,
              mensaje: null,
            });
          });
          validateManualLine(reservations);
        },
      });
    }
  };

  const filtersSelected = filteredInfo || {};

  const headers = [
    [t('text_channel'),
      t('text_locator'),
      t('text_reservation_state'),
      t('text_hotel'),
      t('text_create'),
      t('text_in'),
      t('text_noc'),
      t('text_rooms'),
      t('text_client'),
      t('text_ota_code'),
      t('text_amount_usd'),
      t('text_currency'),
      t('text_amount_eur'),
      t('text_change_rate'),
      t('text_tec_cost'),
      t('text_imp_tec_cost_eur'),
      t('text_fin_cost'),
      t('text_imp_fin_cost_eur'),
      t('text_c_marketing'),
      t('text_imp_cost_marketing_eur'),
      t('text_c_repudio'),
      t('text_payment_total_eur'),
      t('text_recl_ota')
    ]
  ];

  const csvData = [];

  const channelFilters = [];
  const statusFilters = [];
  const otaCodesFilters = [];
  const currencyFilters = [];

  const hotelOptions = [];

  const hotels = [];

  conciliation?.lineas?.forEach((i) => {
    csvData.push({
      canal: i.canal,
      reservation_id: i.reservation_id,
      status: i.status,
      hotel: i.hotel,
      fecha_creacion_string: i.fecha_creacion_string,
      arrival_string: i.arrival_string,
      nights: i.nights,
      booking: i.booking,
      usuario: i.usuario,
      codigo_ota: i.codigo_ota,
      importe_dolares: Number(i.importe_dolares) ?? Number(0.00),
      moneda: i.moneda,
      importe_euros: Number(i.importe_euros) ?? Number(0.00),
      conversion: Number(i.conversion) ?? Number(0.00),
      comision: Number(i.comision) ?? Number(0.00),
      importe_euros_comision: Number(i.importe_euros_comision) ?? Number(0.00),
      comision_financiero: Number(i.comision_financiero) ?? Number(0.00),
      importe_comision_euros_financiero:
        Number(i.importe_comision_euros_financiero) ?? Number(0.00),
      comision_marketing: Number(i.comision_marketing) ?? Number(0.00),
      importe_comision_euros_marketing:
        Number(i.importe_comision_euros_marketing) ?? Number(0.00),
      importe_repudio:
        Number(i.importe_repudio) ?? Number(0.00),
      payment_total_eur: Number(i.payment_total_eur) ?? Number(0.00),
      reclasificacion_ota: i.reclasificacion_ota,
    });

    if (i.canal !== null && i.canal.length > 0) {
      if (!channelFilters.some((channel) => channel.value === i.canal)) {
        channelFilters.push({
          text: i.canal,
          value: i.canal,
        });
      }
    }
    if (i.status !== null && i.status.length > 0) {
      if (!statusFilters.some(
        (status) => status.value === i.status,
      )) {
        statusFilters.push({
          text: i.status,
          value: i.status,
        });
      }
    }
    if (i.codigo_ota !== null && i.codigo_ota.length > 0) {
      if (!otaCodesFilters.some(
        (otaCode) => otaCode.value === i.codigo_ota,
      )) {
        otaCodesFilters.push({
          text: i.codigo_ota,
          value: i.codigo_ota,
        });
      }
    }
    if (i.moneda !== null && i.moneda.length > 0) {
      if (!currencyFilters.some((currency) => currency.value === i.moneda
        && i.moneda?.length > 0)) {
        currencyFilters.push({
          text: i.moneda,
          value: i.moneda,
        });
      }
    }
    if (i.hotel !== null && i.hotel.length > 0) {
      if (!hotels.some(
        (hotel) => hotel.value === i.id_hotel,
      )) {
        hotelOptions.push(
          <Option key={i.id_hotel} value={i.id_hotel}>
            <Text className="text-option-value">
              {i.hotel}
            </Text>
          </Option>
        );
        hotels.push({
          text: i.hotel,
          value: i.id_hotel,
        });
      }
    }
  });

  conciliation?.lineasManuales?.forEach((i) => {
    csvData.push({
      canal: '',
      reservation_id: i.reservation_id,
      status: '',
      hotel: i.hotel,
      fecha_creacion_string: '',
      arrival_string: '',
      nights: '',
      booking: '',
      usuario: i?.observacion?.trim(),
      codigo_ota: i.codigo_ota,
      importe_dolares: '',
      moneda: '',
      importe_euros: '',
      conversion: '',
      comision: '',
      importe_euros_comision: '',
      comision_financiero: '',
      importe_comision_euros_financiero: '',
      comision_marketing: '',
      payment_total_eur: Number(i.importe_euros_comision) ?? Number(0.00),
      reclasificacion_ota: '',
    });
  });

  channelFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  statusFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));
  otaCodesFilters.sort((a, b) => a.text.localeCompare(b.text, i18n.language));

  const columns = [
    {
      title: (
        <Text className="text">
          {t('text_channel')}
        </Text>),
      dataIndex: 'canal',
      key: 'canal',
      fixed: 'left',
      width: 120,
      sorter: {
        compare: (a, b) => a.canal?.localeCompare(b.canal, i18n.language),
        multiple: 1,
      },
      filteredValue: filtersSelected.channel || null,
      filters: channelFilters,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_locator')}
        </Text>),
      dataIndex: 'reservation_id',
      key: 'reservation_id',
      fixed: 'left',
      width: 180,
      ...getColumnSearchProps('reservation_id'),
      filteredValue: filtersSelected.reservation_id
    },
    {
      title: (
        <Text className="text">
          {t('text_hotel')}
        </Text>),
      dataIndex: 'hotel',
      key: 'hotel',
      width: 180,
      sorter: {
        compare: (a, b) => a.hotel.localeCompare(b.hotel, i18n.language),
        multiple: 2,
      },
      filteredValue: filtersSelected?.hotel || null,
      filters: hotelOptions,
      onFilter: (value, record) => record.hotel.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_reservation_state')}
        </Text>),
      dataIndex: 'status',
      key: 'status',
      width: 180,
      sorter: {
        compare: (a, b) => a.status.localeCompare(b.status, i18n.language),
        multiple: 3,
      },
      filteredValue: filtersSelected.status || null,
      filters: statusFilters,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_create')}
        </Text>),
      dataIndex: 'fecha_creacion',
      key: 'fecha_creacion',
      width: 120,
      sorter: {
        compare: (a, b) => a.fecha_creacion - b.fecha_creacion,
        multiple: 4,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text.length === 0 ? '' : format(text, ConstantsDate.DATE_FORMAT, {
            locale: es,
          })}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_in')}
        </Text>),
      dataIndex: 'arrival',
      key: 'arrival',
      width: 120,
      sorter: {
        compare: (a, b) => a.arrival - b.arrival,
        multiple: 5,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text.length === 0 ? '' : format(text, ConstantsDate.DATE_FORMAT, {
            locale: es,
          })}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_noc')}
        </Text>),
      dataIndex: 'nights',
      key: 'nights',
      width: 60,
      sorter: {
        compare: (a, b) => a.nights - b.nights,
        multiple: 6,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_rooms')}
        </Text>),
      dataIndex: 'booking',
      key: 'booking',
      width: 120,
      sorter: {
        compare: (a, b) => a.booking.localeCompare(b.booking, i18n.language),
        multiple: 7,
      },
      align: 'center',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_ota_code')}
        </Text>),
      dataIndex: 'codigo_ota',
      key: 'codigo_ota',
      width: 150,
      sorter: {
        compare: (a, b) => a.codigo_ota.localeCompare(b.codigo_ota, i18n.language),
        multiple: 8,
      },
      filteredValue: filtersSelected.codigo_ota || null,
      filters: otaCodesFilters,
      onFilter: (value, record) => record.codigo_ota?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_amount_usd')}
        </Text>),
      dataIndex: 'importe_dolares',
      key: 'importe_dolares',
      width: 150,
      sorter: {
        compare: (a, b) => Number(a.importe_dolares) - Number(b.importe_dolares),
        multiple: 9,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_currency')}
        </Text>),
      dataIndex: 'moneda',
      key: 'moneda',
      width: 100,
      filteredValue: filtersSelected.moneda || null,
      filters: currencyFilters,
      onFilter: (value, record) => record.moneda?.indexOf(value) === 0,
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_amount_eur')}
        </Text>),
      dataIndex: 'importe_euros',
      key: 'importe_euros',
      width: 150,
      sorter: {
        compare: (a, b) => Number(a.importe_euros) - Number(b.importe_euros),
        multiple: 10,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_change_rate')}
        </Text>),
      dataIndex: 'conversion',
      key: 'conversion',
      width: 150,
      sorter: {
        compare: (a, b) => Number(a?.conversion) - Number(b?.conversion),
        multiple: 11,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === null || text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_tec_cost')}
        </Text>),
      dataIndex: 'comision',
      key: 'comision',
      width: 120,
      sorter: {
        compare: (a, b) => Number(a?.comision) - Number(b?.comision),
        multiple: 12,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_imp_tec_cost_usd')}
        </Text>),
      dataIndex: 'importe_comision',
      key: 'importe_comision',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a?.importe_comision) - Number(b?.importe_comision),
        multiple: 13,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_imp_tec_cost_eur')}
        </Text>),
      dataIndex: 'importe_euros_comision',
      key: 'importe_euros_comision',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a?.importe_euros_comision) - Number(b?.importe_euros_comision),
        multiple: 14,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_fin_cost')}
        </Text>),
      dataIndex: 'comision_financiero',
      key: 'comision_financiero',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.comision_financiero) - Number(b.comision_financiero),
        multiple: 15,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_imp_fin_cost_usd')}
        </Text>),
      dataIndex: 'importe_comision_dolares_financiero',
      key: 'importe_comision_dolares_financiero',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.importe_comision_dolares_financiero)
          - Number(b.importe_comision_dolares_financiero),
        multiple: 16,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_imp_fin_cost_eur')}
        </Text>),
      dataIndex: 'importe_comision_euros_financiero',
      key: 'importe_comision_euros_financiero',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.importe_comision_euros_financiero)
          - Number(b.importe_comision_euros_financiero),
        multiple: 17,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_c_marketing')}
        </Text>),
      dataIndex: 'comision_marketing',
      key: 'comision_marketing',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.comision_marketing) - Number(b.comision_marketing),
        multiple: 18,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_imp_cost_marketing_usd')}
        </Text>),
      dataIndex: 'importe_comision_dolares_marketing',
      key: 'importe_comision_dolares_marketing',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.importe_comision_dolares_marketing)
          - Number(b.importe_comision_dolares_marketing),
        multiple: 19,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_imp_cost_marketing_eur')}
        </Text>),
      dataIndex: 'importe_comision_euros_marketing',
      key: 'importe_comision_euros_marketing',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.importe_comision_euros_marketing)
          - Number(b.importe_comision_euros_marketing),
        multiple: 20,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_c_repudio')}
        </Text>),
      dataIndex: 'importe_repudio',
      key: 'importe_repudio',
      width: 180,
      sorter: {
        compare: (a, b) => Number(a.importe_repudio)
          - Number(b.importe_repudio),
        multiple: 21,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00 EUR' : `${text} EUR`}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_payment_total_usd')}
        </Text>),
      dataIndex: 'payment_total_usd',
      key: 'payment_total_usd',
      width: 180,
      sorter: {
        compare: (a, b) => a - b,
        multiple: 22,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_payment_total_eur')}
        </Text>),
      dataIndex: 'payment_total_eur',
      key: 'payment_total_eur',
      width: 180,
      sorter: {
        compare: (a, b) => a - b,
        multiple: 23,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text === 0 ? '0.00' : text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_recl_ota')}
        </Text>),
      dataIndex: 'reclasificacion_ota',
      key: 'reclasificacion_ota',
      width: 120,
      sorter: {
        compare: (a, b) => a?.reclasificacion_ota
          .localeCompare(b?.reclasificacion_ota, i18n.language),
        multiple: 24,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text ?? ''}
        </Text>
      ),
    },
    {
      title: '',
      fixed: 'right',
      width: 50,
      responsive: ['md'],
      render: (record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 },
        },
        children: (
          <Row>
            <Tooltip title={t('text_comments')}>
              <Button
                type="link"
                htmlType="button"
                onClick={() => {
                  setRowId(record.id_arreglo);
                  setVisibleDrawer(true);
                  getMessagesManualLine({ id_arreglo: record.id_arreglo });
                }}
                icon={<MessageOutlined style={styles.iconSave} />}
                style={styles.buttonLink}
              />
            </Tooltip>
          </Row>),
      }),
    },
  ];

  const columnsManualLines = [
    {
      title: (
        <Text className="text">
          {t('hint_hotel_name')}
        </Text>),
      dataIndex: 'hotel',
      key: 'hotel',
      sorter: {
        compare: (a, b) => a.hotel?.localeCompare(b.hotel, i18n.language),
        multiple: 1,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_booking_locator')}
        </Text>),
      dataIndex: 'reservation_id',
      key: 'reservation_id',
      sorter: {
        compare: (a, b) => a.reservation_id?.localeCompare(b.reservation_id, i18n.language),
        multiple: 2,
      },
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_type')}
        </Text>),
      dataIndex: 'codigo_ota',
      key: 'codigo_ota',
      responsive: ['md'],
      render: (text, record) => (
        <Text className="text">
          {getOtaCode(record.codigo_ota)}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('hint_concept')}
        </Text>),
      dataIndex: 'concepto',
      key: 'concepto',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_observation')}
        </Text>),
      dataIndex: 'observacion',
      key: 'observacion',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_amount_payment')}
        </Text>),
      dataIndex: 'importe_euros_comision',
      key: 'importe_euros_comision',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: (
        <Text className="text">
          {t('text_amount_total')}
        </Text>),
      dataIndex: 'importe_euros',
      key: 'importe_euros',
      responsive: ['md'],
      render: (text) => (
        <Text className="text">
          {text}
        </Text>
      ),
    },
    {
      title: '',
      width: 50,
      responsive: ['md'],
      render: (record) => ({
        props: {
          style: { background: Colors.BACKGROUND_COLOR_3 },
        },
        children: (
          <Row>
            <Popconfirm
              disabled={!getWritePermissionByTab(ConstantsRoutes.CONCILIATION)}
              placement="bottomRight"
              title={(
                <Text className="text-title-popconfirm">
                  {t('text_ask_remove_manual_line')}
                </Text>
              )}
              icon={<QuestionCircleOutlined className="icon" />}
              onConfirm={() => {
                removeManualLine({manualLineId: record.id_preconciliacion});
              }}
              okText={(
                <Text style={styles.textOkButtonPopconfirm}>
                  {t('text_yes')}
                </Text>
              )}
              cancelText={(
                <Text style={styles.textCancelButtonPopconfirm}>
                  {t('text_no')}
                </Text>
              )}
            >
              {getWritePermissionByTab(ConstantsRoutes.CONCILIATION) ? (
                <Tooltip title={t('text_remove_manual_line')}>
                  <Button
                    type="link"
                    htmlType="button"
                    disabled={!getWritePermissionByTab(ConstantsRoutes.CONCILIATION)}
                    icon={<DeleteRowOutlined style={styles.iconDelete} />}
                    style={styles.buttonLink}
                  />
                </Tooltip>
              ) : <div />}
            </Popconfirm>
          </Row>),
      }),
    }
  ];

  const data = messagesConciliation?.map((item) => ({
    author: item.nombre,
    content: (
      <Text className="text">
        {item.mensaje}
      </Text>
    ),
    datetime: (
      <Text className="text">
        {item?.fecha?.length === 0 ? '' : format(item.fecha, ConstantsDate.DATE_HOUR_FORMAT, {
          locale: es,
        })}
      </Text>
    )
  }));

  const isLoading = loading
    || isFetchingPreviewConciliation
    || isFetchingGetConciliationData
    || isLoadingRemoveManualLine
    || isLoadingValidateManualLine
    || isLoadingSendMessageManualLine
    || isFetchingGetMessagesManualLine;

  return (
    <Spin spinning={isLoading} size="large">
      <BackTop />
      {visibleModal
        ? <NewManualLinesModal conciliation={conciliation} handleCancel={handleCancel} handleOk={handleOk} hotel={location?.state?.hotelId} jobId={location?.state?.jobId} /> : ''}

      <Row justify="end">
        <Col span={24}>
          <Drawer
            title={t('text_comments')}
            placement="right"
            open={visibleDrawer}
            width={1000}
            onClose={() => {
              setVisibleDrawer(false);
            }}
          >
            <List
              className="comment-list"
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item) => (
                <li>
                  <Comment
                    author={item.author}
                    content={item.content}
                    datetime={item.datetime}
                  />
                </li>
              )}
            />
            <Form.Item>
              <TextArea
                rows={4}
                onChange={(e) => {
                  setMessageValue(e.target.value);
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading}
                icon={<SendOutlined />}
                disabled={messageValue.length === 0}
                onClick={() => {
                  sendMessageManualLine({
                    id_arreglo: rowId,
                    message: messageValue,
                  });
                }}
                type="primary"
              >
                {t('button_send')}
              </Button>
            </Form.Item>
          </Drawer>
          <ResumeConciliation conciliation={conciliation} />
          <Row align="middle" justify="end">
            {getWritePermissionByTab(ConstantsRoutes.CONCILIATION) ? (
              <Button
                style={{ marginRight: 16 }}
                type="primary"
                icon={<PlusOutlined />}
                size="small"
                disabled={!getWritePermissionByTab(ConstantsRoutes.CONCILIATION)}
                onClick={() => {
                  setVisibleModal(true);
                }}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
              >
                {t('text_add_manual_lines')}
              </Button>
            ) : <div />}
            {getWritePermissionByTab(ConstantsRoutes.CONCILIATION) ? (
              <Button
                style={{ marginRight: 16, backgroundColor: 'darkred', color: 'white' }}
                onClick={() => showConfirm(3)}
                size="small"
                disabled={mySelectedRows.length === 0
                  || !getWritePermissionByTab(ConstantsRoutes.CONCILIATION)}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
              >
                {t('text_button_exclude')}
              </Button>
            ) : <div />}
            {getWritePermissionByTab(ConstantsRoutes.CONCILIATION) ? (
              <Button
                style={{ marginRight: 16 }}
                onClick={() => showConfirm(1)}
                size="small"
                disabled={mySelectedRows.length === 0
                  || !getWritePermissionByTab(ConstantsRoutes.CONCILIATION)}
                className="btn btn-h-auto text text-w-bold px-3 py-2"
              >
                {t('text_button_des_conciliate')}
              </Button>
            ) : <div />}
          </Row>
          <Table
            style={{ width: '100%' }}
            rowKey="id_arreglo"
            pagination={{
              total: conciliation?.lineas?.length,
              defaultPageSize: 200,
              pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500']
            }}
            size="small"
            scroll={{ x: 1300 }}
            loading={loading}
            columns={columns}
            dataSource={conciliation?.lineas}
            onChange={handleChange}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection(),
            }}
            footer={() => (
              <Row>
                {location.state.hotelId === null && location.state.hotelName === null ? (
                  <Col style={{ marginLeft: 16 }}>
                    <Button
                      type="primary"
                      icon={<FilePdfOutlined />}
                      size="small"
                      onClick={() => {
                        getPreviewConciliation({
                          idJob: location.state.jobId,
                          companyName: location.state.companyName
                        });
                      }}
                      className="btn btn-h-auto text text-w-bold px-3 py-2"
                    >
                      {t('download_excel_client')}
                    </Button>
                  </Col>
                ) : <div />}
              </Row>
            )}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text className="text">
                    {`${t('text_total_values')} ${conciliation?.lineas?.length}`}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
          <Table
            style={{ width: '100%' }}
            rowKey="id_arreglo"
            pagination={{
              total: conciliation?.lineasManuales?.length,
              defaultPageSize: 200,
              pageSizeOptions: ['10', '20', '50', '100', '200', '300', '500']
            }}
            size="small"
            loading={loading}
            columns={columnsManualLines}
            dataSource={conciliation?.lineasManuales}
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell colSpan={3}>
                  <Text className="text">
                    {`${t('text_total_values')} ${conciliation?.lineasManuales ? conciliation?.lineasManuales?.length : 0}`}
                  </Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
    </Spin>
  );
}
