import React, { useEffect } from 'react';
import {
  Avatar,
  Col,
  Popconfirm,
  Row,
  Space,
  Typography,
  Dropdown,
  Menu,
  Button,
} from 'antd';
import 'antd/dist/antd.less';
import {
  BankOutlined,
  BookOutlined,
  CreditCardOutlined,
  QuestionCircleOutlined,
  ReconciliationOutlined,
  ShopOutlined,
  TableOutlined,
  UnorderedListOutlined,
  UserOutlined,
  FileAddOutlined,
  RedoOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Colors from '../../assets/colors/Colors';
import logo from '../../assets/images/group-28.svg';
import actionIndexActive from '../../store/reducers/home/homeActions';
import ConstantsValue from '../../constants/ConstantsValue';
import actionClearData from '../../store/reducers/storeGlobalActions';
import {
  getReadPermissionByTab,
  getIsAdminPermission,
  getWritePermissionByTab
} from '../../utils/Utils';
import ConstantsRoutes from '../../constants/ConstantsRoutes';
import TopBarItem from './topBarAux/TopBarItem';
import BurgerMenu from './topBarAux/BurgerMenu';
import TopBarGroup from './topBarAux/TopBarGroup';
import AsyncProcessSubMenu from './bulkActions/AsyncProcessSubMenu';

const { Text } = Typography;
const styles = {
  iconActive: {
    color: Colors.PRIMARY_COLOR,
    fontSize: 20
  },
  iconInactive: {
    color: '#104D68',
    fontSize: 20
  },
  iconGroup: {
    color: 'white',
    fontSize: 20
  }
};
export default function TopBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    switch (location.pathname) {
      case ConstantsRoutes.PAYMENTS:
        changeIndex(1);
        break;
      case ConstantsRoutes.INVITATIONS:
        changeIndex(3);
        break;
      case ConstantsRoutes.INVITATION_ADD:
        changeIndex(12);
        break;
      case ConstantsRoutes.TPV_SECURE_MANUAL:
        changeIndex(13);
        break;
      case ConstantsRoutes.PRE_CONCILIATION:
        changeIndex(5);
        break;
      case ConstantsRoutes.CONCILIATIONS:
        changeIndex(6);
        break;
      case ConstantsRoutes.CONCILIATION:
        changeIndex(6);
        break;
      case ConstantsRoutes.TPV_MANUAL:
        changeIndex(7);
        break;
      case ConstantsRoutes.TPV_TRANSACTIONS:
        changeIndex(8);
        break;
      case ConstantsRoutes.COLLECTION_REQUESTS:
        changeIndex(10);
        break;
      case ConstantsRoutes.PAYMENT_REQUESTS:
        changeIndex(11);
        break;
      default:
        changeIndex(1);
        break;
    }
  }, []);

  const onClickLogout = () => {
    dispatch(actionClearData());
    Cookies.remove(ConstantsValue.JWT);
    navigate('/login');
  };
  const handleMenuClick = (e) => {
    switch (e.key) {
      case 'invitations_pay':
        changeIndex(12); // El número aquí representa el índice o identificador para tus rutas/vistas
        break;
      case 'invitations_pay_secure':
        changeIndex(13); // El número aquí representa el índice o identificador para tus rutas/vistas
        break;
      case 'tpv_manual':
        changeIndex(7); // El número aquí representa el índice o identificador para tus rutas/vistas
        break;
      case 'preconciliacion':
        changeIndex(5); // El número aquí representa el índice o identificador para tus rutas/vistas
        break;
      case 'conciliacion':
        changeIndex(6); // El número aquí representa el índice o identificador para tus rutas/vistas
        break;
      case 'solicitud_cobro':
        changeIndex(10); // El número aquí representa el índice o identificador para tus rutas/vistas
        break;
      case 'solicitud_pago':
        changeIndex(11); // El número aquí representa el índice o identificador para tus rutas/vistas
        break;
      case 'invitations':
        changeIndex(3); // El número aquí representa el índice o identificador para tus rutas/vistas
        break;  
      default:
        console.log('Opción de menú no manejada:', e.key);
    }
  };

  const menuTpvs = (
    <Menu onClick={handleMenuClick}>
      {getReadPermissionByTab(ConstantsRoutes.INVITATIONS) ? (
        <Menu.Item route={ConstantsRoutes.INVITATIONS} key="invitations" icon={<UnorderedListOutlined style={location.pathname === ConstantsRoutes.INVITATIONS ? styles.iconActive : styles.iconInactive} />}>
          {t('text_button_list')}
        </Menu.Item>
      ) : null}
      {getWritePermissionByTab(ConstantsRoutes.INVITATIONS) ? (
        <Menu.Item route={ConstantsRoutes.INVITATION_ADD} key="invitations_pay" icon={<CreditCardOutlined style={location.pathname === ConstantsRoutes.INVITATION_ADD ? styles.iconActive : styles.iconInactive} />}>
          {t('new_invitation')}
        </Menu.Item>
      ) : null}
      {getWritePermissionByTab(ConstantsRoutes.INVITATIONS) ? (
        <Menu.Item route={ConstantsRoutes.TPV_SECURE_MANUAL} key="invitations_pay_secure" icon={<CreditCardOutlined style={location.pathname === ConstantsRoutes.TPV_SECURE_MANUAL ? styles.iconActive : styles.iconInactive} />}>
          {t('new_tpv_secure')}
        </Menu.Item>
      ) : null}
      {getReadPermissionByTab(ConstantsRoutes.TPV_MANUAL) ? (
        <Menu.Item route={ConstantsRoutes.TPV_MANUAL} key="tpv_manual" icon={<CreditCardOutlined style={location.pathname === ConstantsRoutes.TPV_MANUAL ? styles.iconActive : styles.iconInactive} />}>
          {t('text_manual_tpv')}
        </Menu.Item>
      ) : null}
    </Menu>
  );
  const menuConciliaciones = (
    <Menu onClick={handleMenuClick}>
      {getReadPermissionByTab(ConstantsRoutes.PRE_CONCILIATION) ? (
        <Menu.Item route={ConstantsRoutes.PRE_CONCILIATION} key="preconciliacion" icon={<ReconciliationOutlined style={location.pathname === ConstantsRoutes.PRE_CONCILIATION ? styles.iconActive : styles.iconInactive} />}>
          {t('text_button_pre_conciliation')}
        </Menu.Item>
      ) : null}
      {getReadPermissionByTab(ConstantsRoutes.CONCILIATIONS) ? (
        <Menu.Item route={ConstantsRoutes.CONCILIATIONS} key="conciliacion" icon={<BankOutlined style={location.pathname === ConstantsRoutes.CONCILIATIONS ? styles.iconActive : styles.iconInactive} />}>
          {t('text_button_conciliations')}
        </Menu.Item>
      ) : null}
    </Menu>
  );

  const menuSolicitudes = (
    <Menu onClick={handleMenuClick}>
      {getReadPermissionByTab(ConstantsRoutes.COLLECTION_REQUESTS) ? (
        <Menu.Item route={ConstantsRoutes.COLLECTION_REQUESTS} key="solicitud_cobro" icon={<BookOutlined style={location.pathname === ConstantsRoutes.COLLECTION_REQUESTS ? styles.iconActive : styles.iconInactive} />}>
          {t('collection_request')}
        </Menu.Item>
      ) : null}
      {getReadPermissionByTab(ConstantsRoutes.PAYMENT_REQUESTS) ? (
        <Menu.Item route={ConstantsRoutes.PAYMENT_REQUESTS} key="solicitud_pago" icon={<BookOutlined style={location.pathname === ConstantsRoutes.PAYMENT_REQUESTS ? styles.iconActive : styles.iconInactive} />}>
          {t('payment_request')}
        </Menu.Item>
      ) : null}
    </Menu>
  );

  const changeIndex = (index) => {
    dispatch(actionIndexActive(index));
    switch (index) {
      case 1:
        navigate(ConstantsRoutes.PAYMENTS);
        break;
      case 3:
        navigate(ConstantsRoutes.INVITATIONS);
        break;
      case 5:
        navigate(ConstantsRoutes.PRE_CONCILIATION);
        break;
      case 6:
        navigate(ConstantsRoutes.CONCILIATIONS);
        break;
      case 7:
        navigate(ConstantsRoutes.TPV_MANUAL);
        break;
      case 8:
        navigate(ConstantsRoutes.TPV_TRANSACTIONS);
        break;
      case 10:
        navigate(ConstantsRoutes.COLLECTION_REQUESTS);
        break;
      case 11:
        navigate(ConstantsRoutes.PAYMENT_REQUESTS);
        break;
      case 12:
        navigate(ConstantsRoutes.INVITATION_ADD);
        break;
      case 13:
        navigate(ConstantsRoutes.TPV_SECURE_MANUAL);
        break;
      default:
        navigate(ConstantsRoutes.PAYMENTS);
        break;
    }
  };

  return (
    <Row
      align="middle"
      className="bg bg-color-primary pt-3 px-3 flex-col md:flex-row"
    >
      <Col className="p-3" align="start" xs={24} md={4}>
        <img alt="logo" src={logo} height="36px" />
      </Col>
      <Col xs={24} md={18} className="mt-3 md:mt-0">
        <div className="hidden md:block">
          <Row justify="center" align="middle">
            <Space size={14}>
              {getReadPermissionByTab(ConstantsRoutes.PAYMENTS) ? (
                <TopBarItem
                  clickHandler={() => changeIndex(1)}
                  route={ConstantsRoutes.PAYMENTS}
                  label={t('text_button_payments')}
                  icon={(
                    <TableOutlined
                      style={
                    location.pathname === ConstantsRoutes.PAYMENTS
                      ? styles.iconActive
                      : styles.iconGroup
                  }
                    />
              )}
                />
              ) : null}
              {getReadPermissionByTab(ConstantsRoutes.TPV_TRANSACTIONS) ? (
                <TopBarItem
                  clickHandler={() => changeIndex(8)}
                  route={ConstantsRoutes.TPV_TRANSACTIONS}
                  label={t('text_tpv_transactions')}
                  icon={(
                    <ShopOutlined
                      style={
                    location.pathname === ConstantsRoutes.TPV_TRANSACTIONS
                      ? styles.iconActive
                      : styles.iconGroup
                  }
                    />
              )}
                />
              ) : null}
              <Dropdown overlay={menuTpvs} trigger={['click']}>
                <Button type="text" onClick={(e) => e.preventDefault()} style={{ background: 'none', border: 'none', height: '67px' }}>
                  <TopBarGroup
                    route={ConstantsRoutes.INVITATION_ADD}
                    label={t('text_button_pagos_tps')}
                    icon={(
                      <FileAddOutlined
                        style={
                            location.pathname === ConstantsRoutes.INVITATION_ADD
                              ? styles.iconActive
                              : styles.iconGroup
                          }
                      />
                    )}
                  />
                </Button>
              </Dropdown>
              <Dropdown overlay={menuConciliaciones} trigger={['click']}>
                <Button type="text" onClick={(e) => e.preventDefault()} style={{ background: 'none', border: 'none', height: '67px' }}>
                  <TopBarGroup
                    route={ConstantsRoutes.CONCILIATIONS}
                    label={t('text_button_pre_conciliacion')}
                    icon={(
                      <RedoOutlined
                        style={
                            location.pathname === ConstantsRoutes.CONCILIATIONS
                              ? styles.iconActive
                              : styles.iconGroup
                          }
                      />
                    )}
                  />
                </Button>
              </Dropdown>
              <Dropdown overlay={menuSolicitudes} trigger={['click']}>
                <Button type="text" onClick={(e) => e.preventDefault()} style={{ background: 'none', marginLeft: '20px', height: '67px' }}>
                  <TopBarGroup
                    route={ConstantsRoutes.COLLECTION_REQUESTS}
                    label={t('text_button_solicitudes_pago_cobro')}
                    icon={(
                      <ContainerOutlined
                        style={
                            location.pathname === ConstantsRoutes.COLLECTION_REQUESTS
                              ? styles.iconActive
                              : styles.iconGroup
                          }
                      />
                    )}
                  />
                </Button>
              </Dropdown>
            </Space>
          </Row>
        </div>
      </Col>

      <Col xs={24} md={2} style={{display: 'flex'}} className="my-3 md:my-0 justify-between">
        <div className="md:hidden block">
          <BurgerMenu changeIndex={changeIndex} />
        </div>
        <Row justify="end" align="middle" className="mr-0">
          {getIsAdminPermission() ? (
            <Col className="mr-1 flex items-center">
              <AsyncProcessSubMenu />
            </Col>
          ) : (
            ''
          )}
          <Col>
            <Popconfirm
              placement="bottomRight"
              title={<Text>{t('text_question_close_sesion')}</Text>}
              icon={
                <QuestionCircleOutlined className="icon icon-color-secondary" />
              }
              onConfirm={onClickLogout}
              okText={
                <Text className="text text-color-white">{t('text_yes')}</Text>
              }
              cancelText={(
                <Text className="text-base font-['Roboto-Regular']" style={styles.textCancelButtonPopconfirm}>
                  {t('text_no')}
                </Text>
              )}
            >
              <Avatar size="32px" icon={<UserOutlined />} />
            </Popconfirm>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
