import React, { useEffect, useState } from 'react';
import {
  Button, Col, Collapse, DatePicker, Form, Input, message, Row, Select, Typography,
} from 'antd';
import 'antd/dist/antd.less';
import {
  SearchOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import Tooltip from 'antd/es/tooltip';
import {actionLoading} from '../../../store/reducers/loading/loadingActions';
import {
  actionGetFilters,
  actionGetReservations,
  actionSetReservation
} from '../../../store/reducers/payments/paymentsActions';
import ConstantsDate from '../../../constants/ConstantsDate';
import styles from './styles/stylesFilters';

import {
  getIsAdminPermission,
} from '../../../utils/Utils';

const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const { RangePicker } = DatePicker;

const formRef = React.createRef();

function getOptionsForSelect(list, className, value = 'value', label = 'label') {
  return list?.map((i) => (
    <Option key={`${i[value]}`} value={i[value]}>
      <Text className={className}>
        {i[label]}
      </Text>
    </Option>
  ));
}

export default function FilterPayments() {
  const [form] = Form.useForm();

  const [numberLocator, setNumberLocator] = useState('');
  const [externalNumberLocator, setExternalNumberLocator] = useState('');
  const [channel, setChannel] = useState('');
  const [nameHotel, setNameHotel] = useState('');
  const [dateCreateStart, setDateCreateStart] = useState(null);
  const [dateCreateEnd, setDateCreateEnd] = useState(null);
  const [dateCheckinStart, setDateCheckinStart] = useState(null);
  const [dateCheckinEnd, setDateCheckinEnd] = useState(null);
  const [dateCheckoutStart, setDateCheckoutStart] = useState(null);
  const [dateCheckoutEnd, setDateCheckoutEnd] = useState(null);
  const [stateReservation, setStateReservation] = useState('');
  const [statePay, setStatePay] = useState('');
  const [otaCode, setOtaCode] = useState('');
  const [client, setClient] = useState('');
  const [chain, setChain] = useState('');
  const [currency, setCurrency] = useState('');
  const [concept, setConcept] = useState('');
  const [crs, setCrs] = useState('');
  const [selectAfterValue, setSelectAfterValue] = useState('literal');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.reducerFilters);
  const reservationInvitation = useSelector((state) => state.reducerSetReservation);
  const loading = useSelector((state) => state.reducerLoading);

  useEffect(() => {
    if (!loading) {
      dispatch(actionLoading(true));
      dispatch(actionGetFilters());

      if (reservationInvitation?.reservation !== null) {
        callFilter(null);
        dispatch(actionSetReservation(null));
      }
    }
  }, []);

  useEffect(() => {
    if (filters.msg !== null && filters.result === false) {
      message.error(filters.msg);
    } // TODO: call filter
  });

  const clickClearFilters = () => {
    setNumberLocator('');
    setExternalNumberLocator('');
    setChannel('');
    setNameHotel('');
    setDateCreateStart(null);
    setDateCreateEnd(null);
    setDateCheckinStart(null);
    setDateCheckinEnd(null);
    setDateCheckoutStart(null);
    setDateCheckoutEnd(null);
    setStateReservation('');
    setStatePay('');
    setOtaCode('');
    setClient('');
    setChain('');
    setCurrency('');
    setConcept('');
    setCrs('');
    formRef.current.resetFields();
  };

  const clickFilterButton = (e) => {
    let modifiedNumberLocator = numberLocator;
    if (selectAfterValue === 'contener') {
      if (!numberLocator.startsWith('%') && !numberLocator.endsWith('%')) {
        modifiedNumberLocator = `%${numberLocator}%`;
      }
    } else if (selectAfterValue === 'literal') {
      if (numberLocator.startsWith('%') && numberLocator.endsWith('%')) {
        modifiedNumberLocator = numberLocator.slice(1, -1);
      }
    }
    e.preventDefault();
    callFilter(modifiedNumberLocator);
  };

  function callFilter(modifiedNumberLocator) {
    dispatch(actionLoading(true));
    dispatch(actionGetReservations(
      nameHotel,
      channel,
      !dateCreateStart?.isValid() ? '' : format(dateCreateStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      !dateCreateEnd?.isValid() ? '' : format(dateCreateEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      !dateCheckinStart?.isValid() ? '' : format(dateCheckinStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      !dateCheckinEnd?.isValid() ? '' : format(dateCheckinEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      !dateCheckoutStart?.isValid() ? '' : format(dateCheckoutStart.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      !dateCheckoutEnd?.isValid() ? '' : format(dateCheckoutEnd.toDate(), ConstantsDate.DATE_FORMAT_REQUEST, {
        locale: es,
      }),
      reservationInvitation?.reservation === null ? modifiedNumberLocator : reservationInvitation?.reservation,
      stateReservation,
      statePay,
      otaCode,
      client,
      chain,
      currency,
      concept,
      crs,
      externalNumberLocator,
    ));
  }

  const datesValidation = () => {
    if (dateCreateStart?.isValid() && dateCreateEnd?.isValid()) {
      return true;
    }
    if (dateCheckoutStart?.isValid() && dateCheckoutStart?.isValid()) {
      return true;
    }
    if (dateCheckinStart?.isValid() && dateCheckinEnd?.isValid()) {
      return true;
    }
    return false;
  };

  const datesDifferenceValidator = (rule, value) => {
    if (!value || value?.length < 2) {
      return Promise.resolve();
    }
    if (!getIsAdminPermission() && !datesDifferenceLessThanAux(value[0], value[1])) {
      return Promise.reject(t('cant_filter_by_more_than_3_months'));
    }
    return Promise.resolve();
  };

  const datesDifferenceLessThanAux = (dateStart, dateEnd, daysDifference = 90) => {
    if (!dateStart || !dateEnd) return true;
    const date1 = dateStart.toDate();
    const date2 = dateEnd.toDate();

    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
    const differenceInDays = Math.round(Math.abs((date1 - date2) / oneDay));
    if (differenceInDays > daysDifference) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return false;
    }
    return true;
  };

  const datesDifferenceValidation = () => datesDifferenceLessThanAux(dateCreateStart, dateCreateEnd,)
      && datesDifferenceLessThanAux(dateCheckoutStart, dateCheckoutEnd)
      && datesDifferenceLessThanAux(dateCheckinStart, dateCheckinEnd);

  function isDisabledSubmit() {
    if (!getIsAdminPermission() && !datesDifferenceValidation()) {
      return true;
    }
    if (numberLocator?.length || externalNumberLocator?.length) {
      return false;
    }
    return !datesValidation();
  }
  const hotelsOptions = getOptionsForSelect(filters?.hotels.filter((item) => !chain || item.id_cadena === chain), 'text-option-value');
  const channelsOptions = getOptionsForSelect(filters?.channels, 'text-option-value', 'channel', 'label');
  const crsOptions = getOptionsForSelect(filters?.crs, 'text-option-value');
  const statesReservationOptions = getOptionsForSelect(filters?.reservationStates, 'text-option-value', 'status', 'label');
  const statesPayOptions = getOptionsForSelect(filters?.payStates, 'text-option-value', 'estado', 'label');
  const otaCodesOptions = getOptionsForSelect(filters?.payForms, 'text-option-value', 'tipo', 'label');
  const clientsOptions = getOptionsForSelect(filters?.clients, 'text-option-value', 'cliente', 'label');
  const chainsOptions = getOptionsForSelect(filters?.chains, 'text-option-value', 'id_cadena', 'nombre_cadena');
  const currencyOptions = getOptionsForSelect(filters?.currencies, 'text-option-value', 'currency', 'label');
  const conceptOptions = getOptionsForSelect(filters?.concepts, 'text-option-value', 'tipo', 'label');
  const selectAfter = (
    <Select defaultValue="literal" onChange={(value) => setSelectAfterValue(value)}>
      <Option value="contener">contendra</Option>
      <Option value="literal">literal</Option>
    </Select>
  );

  return (
    <Row align="middle" style={styles.rowFilter} className="remaquia">
      <Collapse
        accordion
        defaultActiveKey={['1']}
        style={{ width: '100%' }}
        expandIconPosition="end"
      >
        <Panel
          key="1"
          header={(
            <Text style={styles.textTitle}>
              {t('text_search_filters')}
            </Text>
          )}
        >
          <Form
            name="advanced_search"
            className="ant-advanced-search-form"
            labelCol={{
              span: 10,
            }}
            ref={formRef}
            onSubmit={clickFilterButton}
            form={form}
          >
            <Row gutter={24}>
              <Col xs={24} md={8}>
                <Form.Item
                  name="field1"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_booking_locator')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    addonAfter={selectAfter}
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('hint_number_locator')}
                    onChange={(text) => {
                      dispatch(actionSetReservation(null));
                      setNumberLocator(text.target.value);
                    }}
                    value={numberLocator}
                    style={styles.specialInput}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="crs"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_crs')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_crs')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setCrs(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={crs}
                  >
                    {crsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="create_date"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_create_date')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                    { validator: datesDifferenceValidator },
                  ]}
                >
                  <RangePicker
                    style={styles.input}
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateCreateStart(dates === null ? null : dates[0]);
                      setDateCreateEnd(dates === null ? null : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="external_booking_locator"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_external_booking_locator')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    suffix={<SearchOutlined style={styles.icon} />}
                    placeholder={t('text_external_booking_locator')}
                    onChange={(text) => {
                      dispatch(actionSetReservation(null));
                      setExternalNumberLocator(text.target.value);
                    }}
                    value={externalNumberLocator}
                    style={styles.input}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="chain"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_chain')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_chain')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setChain(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={chain}
                  >
                    {chainsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="dateCheckin"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_check_in')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                    { validator: datesDifferenceValidator },
                  ]}
                >
                  <RangePicker
                    style={styles.input}
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateCheckinStart(dates === null ? dates : dates[0]);
                      setDateCheckinEnd(dates === null ? dates : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="stateReservation"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_reservation_state')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_reservation_state')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setStateReservation(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={stateReservation}
                  >
                    {statesReservationOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="nameHotel"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_hotel')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    style={styles.select}
                    placeholder={t('hint_hotel_name')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setNameHotel(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={nameHotel}
                  >
                    {hotelsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="dateCheckout"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_check_out')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                    { validator: datesDifferenceValidator },
                  ]}
                >
                  <RangePicker
                    style={styles.input}
                    format={ConstantsDate.DATE_FORMAT_PICKER}
                    onChange={(dates) => {
                      setDateCheckoutStart(dates === null ? dates : dates[0]);
                      setDateCheckoutEnd(dates === null ? dates : dates[1]);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="statePay"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_pay_state')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_pay_state')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setStatePay(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={statePay}
                  >
                    {statesPayOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="channel"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_channel')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('hint_channel')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setChannel(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={channel}
                  >
                    {channelsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="payForm"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_ota_code')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    dropdownStyle={{ minWidth: '40%' }}
                    allowClear
                    placeholder={t('text_ota_code')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setOtaCode(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={otaCode}
                  >
                    {otaCodesOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="currency"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_currency')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    style={styles.select}
                    placeholder={t('text_currency')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setCurrency(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={currency}
                  >
                    {currencyOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="client"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_client')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    style={styles.select}
                    placeholder={t('text_client')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setClient(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                    }
                    value={client}
                  >
                    {clientsOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item
                  name="concept"
                  label={(
                    <Text style={styles.textTitleDescription}>
                      {t('text_concept')}
                    </Text>
                  )}
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={styles.select}
                    allowClear
                    placeholder={t('text_concept')}
                    optionFilterProp="children"
                    onChange={(value) => {
                      setConcept(value);
                    }}
                    filterOption={
                      (
                        input,
                        option
                      ) => option.children.props.children.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={concept}
                  >
                    {conceptOptions}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  name="buttons"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Row justify="end">
                    <Col>
                      <Button
                        onClick={clickClearFilters}
                        style={styles.buttonDefault}
                      >
                        {t('text_button_clear')}
                      </Button>
                    </Col>
                    <Col>
                      <Tooltip title={isDisabledSubmit() ? t('mustFilterAtLeastByOneDateField') : null}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          onClick={clickFilterButton}
                          style={styles.buttonPrimary}
                          disabled={isDisabledSubmit()}
                        >
                          {t('text_button_filter')}
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Panel>
      </Collapse>
    </Row>
  );
}
